import './app.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SettingsPage from './pages/SettingsPage';
import ChatsPage from './pages/ChatsPage';
import ShopPage from './pages/ShopPage';
import RequestsPage from './pages/RequestsPage';
import AddCategoryPage from './pages/AddCategoryPage';
import CreateShopPage from './pages/CreateShopPage';
import CreateSupplierPage from './pages/CreateSupplierPage';
import EditShopPage from './pages/EditShopPage';
import AddProductPage from './pages/AddProductPage';
import MainPage from './pages/MainPage';
import EventsPage from './pages/EventsPage';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getEventsTC } from './state/events-reducer';
import EventDetailPage from './pages/EventDetailPage';
import BuyTicketPage from './pages/BuyTicketPage';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import { getUserTicketsTC } from './state/user-reducer';
import ActiveTicketsPage from './pages/ActiveTicketsPage';
import ArchiveTicketsPage from './pages/ArchiveTicketsPage';

function App() {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getEventsTC());
    dispatch(getUserTicketsTC());
  }, [dispatch]);

  return (
    <div className="app">
      <HashRouter>

        <ScrollToTop />
        
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/requests" element={<RequestsPage />} />
          <Route path="/chats" element={<ChatsPage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/shop/edit-shop" element={<EditShopPage />} />
          <Route path="/shop/add-product" element={<AddProductPage />} />
          <Route path="/shop/add-category" element={<AddCategoryPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/tickets" element={<ActiveTicketsPage />} />
          <Route path="/tickets/archive" element={<ArchiveTicketsPage />} />
          <Route path="/events/:eventId" element={<EventDetailPage />} />
          <Route path="/events/:eventId/buy-ticket" element={<BuyTicketPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/settings/create-shop" element={<CreateShopPage />} />
          <Route
            path="/settings/create-supplier"
            element={<CreateSupplierPage />}
          />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
