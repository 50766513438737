import React from 'react'
import Header from '../components/header/Header'
import EventDetail from '../components/event-detail/EventDetail'

const EventDetailPage = () => {
  return (
    <div className="page events-page">
      <Header />
      <EventDetail />
    </div>
  )
}

export default EventDetailPage