import React from 'react';
import './tickets-list.css';
import TicketItem from '../tickets-item/TicketItem';
import { TicketType } from '../../state/user-reducer';

type TicketsListPropsType = {
  tickets: TicketType[];
};
const TicketsList = ({ tickets }: TicketsListPropsType) => {
  return (
    <div className="tickets-list">
      {tickets.map((ticket) => (
        <TicketItem key={ticket.id} ticket={ticket} />
      ))}
    </div>
  );
};

export default TicketsList;
