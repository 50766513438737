import React from 'react'
import Header from '../components/header/Header'
import ActiveTickets from '../components/tickets/ActiveTickets'


const ActiveTicketsPage = () => {
  return (
    <div className='page tickets-page'>
        <Header />

        <ActiveTickets />
    </div>
  )
}

export default ActiveTicketsPage