import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";
import { eventsReducer } from "./events-reducer";
import { userReducer } from "./user-reducer";


export const rootReducer = combineReducers({
    events: eventsReducer,
    userInfo: userReducer
})

export const store = createStore(rootReducer, undefined, applyMiddleware(thunk));

export type RootStateType = ReturnType<typeof rootReducer>;

