import React from 'react';
import Header from '../components/header/Header';
import ArchiveTickets from '../components/tickets/ArchiveTickets';

const ArchiveTicketsPage = () => {
  return (
    <div className="page archive-tickets-page">
      <Header />

      <ArchiveTickets />
    </div>
  );
};

export default ArchiveTicketsPage;
