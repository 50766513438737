import React from 'react'
import Header from '../components/header/Header'
import BuyTicketForm from '../components/buy-ticket-form/BuyTicketForm'

const BuyTicketPage = () => {
  return (
    <div className='page buy-ticket-page'>
        <Header />
        <BuyTicketForm />
    </div>
  )
}

export default BuyTicketPage