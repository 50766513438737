import axios from 'axios';
import { Dispatch } from 'redux';
import { mainURL } from '../widgets/config';

enum UserEnumType {
  SET_USER_TICKETS = 'SET_USER_TICKETS',
}

interface TicketItem {
  id: string;
  qr_code: string;
  status: string;
}

export interface TicketType {
  id: number;
  created_at: string;
  event: number;
  event_date: string;
  event_title: string;
  expires_at: string;
  quantity: number;
  status: string;
  tickets: TicketItem[];
  total_price: string;
  user: number;
}

export type UserStateType = {
  tickets: {
    active: TicketType[];
    archive: TicketType[];
  };
};

export type SetUserTicketsActionType = {
  type: UserEnumType.SET_USER_TICKETS;
  tickets: TicketType[];
};

const initialState: UserStateType = {
  tickets: {
    active: [],
    archive: [],
  },
};

type UserActionType = SetUserTicketsActionType;

export const userReducer = (
  state: UserStateType = initialState,
  action: UserActionType
) => {
  switch (action.type) {
    case UserEnumType.SET_USER_TICKETS:
      return {
        ...state,
        tickets: {
          active: action.tickets.filter(
            (ticket) => ticket.status !== 'cancelled'
          ),
          archive: action.tickets.filter(
            (ticket) => ticket.status === 'cancelled'
          ),
        },
      };
    default:
      return state;
  }
};

export const setUserTicketsAC = (
  tickets: TicketType[]
): SetUserTicketsActionType => ({
  type: UserEnumType.SET_USER_TICKETS,
  tickets,
});

export const getUserTicketsTC = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(mainURL + '/en/api/v1/events/orders/', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
      });
      console.log('events tickets', response.data);
      dispatch(setUserTicketsAC(response.data.results));
    } catch (error) {
      console.error('Failed to get events tickets:', error);
    }
  };
};

export const getSavedCardsTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(
        mainURL + '/en/api/v1/users/profile/saved-cards/',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('saved cards', response.data);
    } catch (error) {
      console.error('Failed to get saved cards:', error);
    }
  };
};
