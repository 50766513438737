import { useState } from 'react';
import GoBackButton from '../../elements/go-back-button/GoBackButton';
import './buy-ticket-form.css';
import CreditCardForm from '../credit-card-form/CreditCardForm';

const BuyTicketForm = () => {
  const [isNewCard, setIsNewCard] = useState(false);

  return (
    <div className="buy-ticket">
      <GoBackButton />

      <p className="buy-ticket-title">Оплата</p>

      <div className="buy-ticket-form">
        <div className="payment-section">
          <h2 className="payment-section__label">Продукты</h2>
          <p className="payment-section__value">
            Билет в Чашка кофе в Starbucks
          </p>
        </div>

        <div className="payment-section double">
          <h2 className="payment-section__label">Цена</h2>
          <p className="payment-section__value">5000.00</p>
        </div>

        <div className="payment-subtitle">Ваши сохраненные карты</div>

        <div className="card-wrapper">
          <p className="card-wrapper__number">**** 0445</p>
          <p className="card-wrapper__name">Bauyrzhan iten</p>
        </div>

        {isNewCard ? (
          <CreditCardForm />
        ) : (
          <button className="payment-new-card" onClick={() => setIsNewCard(true)}>Использовать новую карту</button>
        )}

        <button className="payment-submit">Оплатить</button>
      </div>
    </div>
  );
};

export default BuyTicketForm;
