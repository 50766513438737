import './header.css';
import logo from '../../static/img/stubla_logo.png';

import { NavLink, useNavigate } from 'react-router-dom'; // Import NavLink from react-router-dom
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const Header = () => {
    const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")

    navigate('/');
  };


  return (
    <header className="header">
      <div className="header-top">
        <img src={logo} alt="Logo" />
        <div className="header-buttons">
          <button className="header-button" onClick={handleLogout}>
            Выйти
          </button>
        </div>
      </div>

      <hr />

      <ul className="header-nav">
        <li>
          <NavLink
            to="/requests"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <RequestPageOutlinedIcon />
            Запросы
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/chats"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <TextsmsOutlinedIcon />
            Чаты
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/shop"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <StorefrontIcon />
            Магазин
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/events"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <SendOutlinedIcon style={{ rotate: "-45deg" }}/>
            Ивенты
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/settings"
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <SettingsOutlinedIcon /> Настройки
          </NavLink>
        </li>
      </ul>
    </header>
  );
};

export default Header;
