import React from 'react'
import Header from '../components/header/Header'
import Events from '../components/events/Events'

const EventsPage = () => {
  return (
    <div className="page events-page">
      <Header />
      <Events />
    </div>
  )
}

export default EventsPage